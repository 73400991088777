import { Box, Container, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { PageAssociated } from '../../shared/components/layout/pagesAssociated';
import { GiftBagCard } from './components/GiftBagCard';
import { PlanNewCard } from './components/PlanNewCard';
import {
  PaypalStatuses,
  Plan,
  usePlansQuery,
} from '../../shared/types/generated';
import { termAndCondition } from './components/termAndConditions';

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    banner: {
      backgroundImage: 'url("/plans-banner.png")',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      margin: '0 auto',
    },
    title: {
      fontSize: '40px !important',
      fontWeight: 'bolder !important',
      textAlign: 'center',
      textShadow: '2px 2px #00000060',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px !important',
        fontWeight: '900 !important',
        textShadow: '1.5px 1px #00000060',
        lineHeight: '30px',
      },
    },
    subTitle: {
      fontSize: '22px !important',
      lineHeight: '28px !important',
      textAlign: 'center',
      color: '#414141',
      maxWidth: '750px',
      margin: '0 auto !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '18px !important',
        lineHeight: '24px !important',
      },
    },
    extraletters: {
      fontSize: '16px !important',
      lineHeight: '22px !important',
      fontFamily: 'acumin-pro',
      [theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
        lineHeight: '19px !important',
      },
    },
    extralettersTwo: {
      fontSize: '18px !important',
      lineHeight: '24px !important',
      [theme.breakpoints.down('md')]: {
        fontSize: '13px !important',
        lineHeight: '19px !important',
      },
    },
    plansSection: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexDirection: 'row',
      width: '1040px',
      [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      },
    },
    listStyle: {
      padding: '0px 0px 0px 40px',
      [theme.breakpoints.down('lg')]: {
        padding: '0px 22px',
      },
    },
    listContainer: {
      width: '1040px',
      [theme.breakpoints.down('lg')]: {
        width: '100%',
      },
    },
    benefits: {
      paddingTop: '15px !important',
      padding: '0 40px !important',
      [theme.breakpoints.down('sm')]: {
        paddingTop: '15px !important',
        padding: '0 10px !important',
      },
    },
  }),
);

export const NewPlansPage: React.FC = () => {
  const { data, loading } = usePlansQuery({
    variables: {
      where: {
        status: PaypalStatuses.Active,
        is_premium: true,
      },
    },
  });
  const classes = useStyles();
  return (
    <div>
      <Box
        height={'130px'}
        width={'100%'}
        maxWidth={'1500px'}
        className={classes.banner}
      />
      <Container maxWidth={'lg'}>
        <Typography
          fontFamily={'acumin-pro'}
          className={classes.title}
          mt={'48px'}
        >
          ¡Únete a la Familia Animal y obtén beneficios!
        </Typography>
        <Typography
          fontFamily={'acumin-pro'}
          className={classes.subTitle}
          pt={'30px'}
        >
          Sé parte del equipo que así como hace periodismo que investiga la
          corrupción, denuncia la ineficiencia y exige rendición de cuentas; te
          cuenta historias divertidas, útiles y comparte contigo las mejores
          experiencias culturales y deliciosas.
        </Typography>
        <Box display={'flex'} justifyContent={'center'} mt={'30px'}>
          <Box width={'455px'}>
            <PageAssociated />
          </Box>
        </Box>
        <Box mt={'55px'} display={'flex'} justifyContent={'center'}>
          <GiftBagCard />
        </Box>
        {!loading && (
          <Box display={'flex'} justifyContent={'center'}>
            <Box className={classes.plansSection} mt={'49px'}>
              {data?.plans?.items?.map((_, index) => (
                <PlanNewCard
                  key={'plan' + index}
                  plan={
                    data?.plans?.items?.[
                      data?.plans?.items?.length - 1 - index
                    ] as Plan
                  }
                />
              ))}
            </Box>
          </Box>
        )}

        <Box display={'flex'} justifyContent={'center'} pb={4}>
          <Box className={classes.listContainer}>
            <Typography
              fontFamily={'acumin-pro'}
              mt={4}
              fontWeight={700}
              lineHeight={'16px'}
            >
              Términos y condiciones
            </Typography>
            <ul className={classes.listStyle}>
              {termAndCondition.map((item, index) => (
                <li key={'termsList' + index} className={classes.extraletters}>
                  {item}
                </li>
              ))}
            </ul>
            <Typography
              fontFamily={'acumin-pro'}
              className={classes.extralettersTwo}
              mt={4}
              fontWeight={700}
            >
              ¿Ya te has suscrito?{' '}
              <a style={{ color: '#000000' }} href="/autenticacion">
                Prueba con otro correo electrónico
              </a>
            </Typography>
            <Typography
              fontFamily={'acumin-pro'}
              mt={2}
              className={classes.extraletters}
            >
              Si tienes alguna pregunta o inquietud, ponte en contacto con
              nosotros a través del correo electrónico:{' '}
              <a
                style={{ color: '#000000' }}
                href="mailto:suscripciones@animalpolitico.com"
              >
                suscripciones@animalpolitico.com
              </a>{' '}
              de lunes a viernes de las 9:00 a las 18:00 horas y te
              contestaremos a la brevedad.
            </Typography>
          </Box>
        </Box>
      </Container>
    </div>
  );
};
