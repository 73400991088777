import { ReactNode } from 'react';

export const professionalPlanBenefits: (string | ReactNode)[] = [
  'Acceso a Animal Político y Animal Gourmet libre de publicidad.',
  'Acceso anticipado a reportajes especiales antes de que estén disponibles para todos los lectores.',
  'Inscripción a boletines informativos exclusivos para suscriptores.',
  'Talleres y eventos especiales con nuestros periodistas.',
  <div
    style={{
      fontWeight: 'bold',
      color: '#FF3E00',
    }}
  >
    <span>Bolsa tote + libro</span>
    <br />
    <span>(hasta agotar existencia)</span>
  </div>,
];
export const studentsPlanBenefits: (string | ReactNode)[] = [
  'Acceso a Animal Político y Animal Gourmet libre de publicidad.',
  'Acceso anticipado a reportajes especiales antes de que estén disponibles para todos los lectores.',
  'Inscripción a boletines informativos exclusivos para suscriptores.',
  'Talleres y eventos especiales con nuestros periodistas.',
];
export const standardPlanBenefits: (string | ReactNode)[] = [
  'Acceso a Animal Político y Animal Gourmet libre de publicidad.',
  'Acceso anticipado a reportajes especiales antes de que estén disponibles para todos los lectores.',
  'Inscripción a boletines informativos exclusivos para suscriptores.',
  'Talleres y eventos especiales con nuestros periodistas.',
];
