import React, { useState, ChangeEvent } from 'react';
import {
  Box,
  Checkbox,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { CalendarMonth, CreditCard, Key, Person } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { useStore } from '@cobuildlab/react-simple-state';
import { changePlanStore } from '../payment/payment-events';
import { checkCreditCard } from './open-pay-utils';
import { openDialogAlert } from '../alert/alert-actions';
import cardValidator from 'card-validator';
import { createOpenPaySubscription } from './open-pay-actions';
import {
  SubscriptionBillingInput,
  useCreateOpenPayChargeMutation,
  useCreateOpenPaySubscriptionMutation,
} from '../../shared/types/generated';
import { PRIMARY_COLOR } from '../../shared/css/theme';
import { getPostUrl } from '../auth/auth-utils';
import { APP_ENDPOINT } from '../../shared/constans';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    icon: {
      color: '#999999',
      fontSize: '20px!important',
      paddingRight: '5px',
    },
    input: {
      background: 'white',
      fontSize: '18px',
      height: '48px',
    },
    button: {
      borderRadius: '5px!important',
      backgroundColor: `${PRIMARY_COLOR}!important`,
      border: 'none!important',
      color: 'white!important',
      '&:disabled': {
        backgroundColor: '#FFCCCC!important',
      },
      height: 45,
    },
  }),
);

type CreditCardType = {
  cardHolderName: string;
  expiredDate: string;
  cvc: string;
  creditCardNumber: string;
};

type OpenPayProps = {
  subscriptionBilling: SubscriptionBillingInput | null

}
export const OpenPay: React.FC<OpenPayProps> = (
  { subscriptionBilling = null },
) => {
  const classes = useStyles();
  const [creditCard, setCreditCard] = useState<CreditCardType>({
    cardHolderName: '',
    expiredDate: '',
    cvc: '',
    creditCardNumber: '',
  });
  const { plan } = useStore(changePlanStore);
  const [loading, setLoading] = useState(false);
  const [conditionAccepted, setConditionAccepted] = useState<boolean>(false);
  const [callSubscriptionAction] = useCreateOpenPaySubscriptionMutation({
    onCompleted: () => {
      setLoading(false);
      // TODO: CAMBIAR URL DE VERIFICACIÓN
      window.location.href =
        APP_ENDPOINT + '/agradecimiento?redirect_url=' + getPostUrl();
    },
    onError: (data) => {
      setLoading(false);
      openDialogAlert(data.message);
    },
  });

  const [callChargeAction] = useCreateOpenPayChargeMutation({
    onCompleted: (data) => {
      setLoading(false);
      // TODO: CAMBIAR URL DE VERIFICACIÓN
      const relocate = data.createOpenPayCharge?.redirect_url as string;
      window.location.href = relocate.includes('?id=')
        ? relocate + '&redirect_url=' + getPostUrl()
        : relocate;
    },
    onError: (data) => {
      setLoading(false);
      openDialogAlert(data.message);
    },
  });

  const onChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setCreditCard({
      ...creditCard,
      [event.target.name as keyof CreditCardType]: event.target.value as string,
    });
  };

  const onHandlePayment = async (): Promise<void> => {
    const checked = checkCreditCard(creditCard);

    const subscriptionBillingChecked = subscriptionBilling &&
      Object.keys(subscriptionBilling).find((key) => !subscriptionBilling[key as keyof SubscriptionBillingInput]);

    if (plan?.subscription_billing_is_required && subscriptionBillingChecked) {
      openDialogAlert('Los datos de envio son obligatorios para el plan anual');
    }

    if (checked) {
      return openDialogAlert(checked);
    }

    const date = cardValidator.expirationDate(creditCard.expiredDate);

    const sanitizeCreditCard = {
      card_number: (creditCard.creditCardNumber as string).replaceAll(' ', ''),
      holder_name: creditCard.cardHolderName as string,
      cvv2: creditCard.cvc as string,
      expiration_month: date.month as string,
      expiration_year: date.year as string,
    };

    setLoading(true);

    await createOpenPaySubscription(
      sanitizeCreditCard,
      (data) => {
        const obj = {
          ...data,
          planId: plan?.id,
          billingInfo: subscriptionBilling,
        };
        return !plan?.open_pay?.first_payment_price
          ? callSubscriptionAction({
            variables: {
              data: { ...obj, subscriptionUrl: getPostUrl() },
            },
          })
          : callChargeAction({
            variables: {
              data: obj,
            },
          });
      },
      () => {
        openDialogAlert('Tenemos problemas para crear la suscripción');
        setLoading(false);
      },
    );
  };

  if (!plan?.open_pay)
    return (
      <Typography variant="subtitle1" fontWeight={800} textAlign="center">
        Open Pay no esta configurado para este plan, contacte a su administrador
      </Typography>
    );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          placeholder={'Nombre del titular'}
          name="cardHolderName"
          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          variant="outlined"
          InputProps={{
            startAdornment: <Person className={classes.icon} />,
            className: classes.input,
          }}
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <InputMask
          mask="9999 9999 9999 9999"
          maskChar=" "
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          maskPlaceholder={null}
          onChange={(event) => onChangeHandler(event)}
        >
          <TextField
            placeholder={'Número de tarjeta'}
            name="creditCardNumber"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <CreditCard className={classes.icon} />,
              className: classes.input,
            }}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: 19 },
            }}
          />
        </InputMask>
      </Grid>

      <Grid item xs={12}>
        <InputMask
          mask="99/99"
          maskChar=" "
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          maskPlaceholder={null}
          onChange={(event) => onChangeHandler(event)}
        >
          <TextField
            placeholder={'Fecha de vencimiento'}
            name="expiredDate"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <CalendarMonth className={classes.icon} />,
              className: classes.input,
            }}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: 16 },
            }}
          />
        </InputMask>
      </Grid>
      <Grid item xs={12}>
        <InputMask
          mask="999"
          maskChar=" "
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          maskPlaceholder={null}
          onChange={(event) => onChangeHandler(event)}
        >
          <TextField
            placeholder={'CVV/CVC'}
            name="cvc"
            fullWidth
            variant="outlined"
            InputProps={{
              startAdornment: <Key className={classes.icon} />,
              className: classes.input,
            }}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: 16 },
            }}
          />
        </InputMask>
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} alignItems={'start'}>
          <Checkbox
            size="small"
            style={{ padding: '2px 0px', marginRight: '5px' }}
            onChange={() => setConditionAccepted(!conditionAccepted)}
          />
          <Typography component={'span'} color={'#414141'}>
            He leído el{' '}
            <a
              style={{ color: '#414141' }}
              href="https://www.animalpolitico.com/aviso-privacidad"
            >
              aviso de privacidad
            </a>{' '}
            y acepto el uso de mis datos personales.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <ButtonDefault
          variant="contained"
          size="large"
          fullWidth
          disabled={!conditionAccepted}
          isLoading={loading}
          onClick={() => onHandlePayment()}
          className={classes.button}
        >
          Pagar
        </ButtonDefault>
      </Grid>
    </Grid>
  );
};
